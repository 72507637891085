import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sadia/my_blog/src/pages/markdownpage-template.js";
import { Link, graphql } from "gatsby";
import Excerpt from "../components/Excerpt";
export const pageQuery = graphql`
  query {
  allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {category: {eq: "book review"}}}) {
    nodes {
      frontmatter {
        date(formatString: "DD MMM YYYY")
        title
        tags
        category
        rating
        thumbnail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      fields {
        slug
      }
    }
  }
}
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ul style={{
      listStyleType: 'none'
    }}>
  {props.data.allMdx.nodes.map(node => <li key={node.id}>
      <Excerpt data={node} mdxType="Excerpt" />
    </li>)}
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      